import AffiliatesCarousel from "../carousels/AffiliatesCarousel";
import PicksCarousel from "../carousels/PicksCarousel";
import Content from "../Content";
import Sidebar from "../sidebar/Sidebar";
import Ticker from "../Ticker";
import TopBannerImageHomepage from "../TopBannerImageHomepage";

const TopSideLayout = ({ children, siteContent, banner, isBannerActive }) => {
  return (
    <>
      <div className="container px-0 xl:max-w-screen-2xl ">
        <div className="mt-4 px-4">
          <Ticker />
        </div>
        {isBannerActive && (
          <div className="mt-4 block px-4 lg:hidden">
            <TopBannerImageHomepage banner={banner} />
          </div>
        )}
        <AffiliatesCarousel />
        <PicksCarousel />
        <div className="grid grid-cols-1 lg:grid-cols-[66.6%_33.3%]  xl:grid-cols-[70%_30%]">
          <div className="px-4 py-6 lg:py-[34px]">{children}</div>
          <div className="hidden p-4 lg:block">
            <Sidebar />
          </div>
        </div>
      </div>
      {siteContent && <Content content={siteContent} />}
    </>
  );
};

export default TopSideLayout;
