import Link from "next/link";

import labels from "@/config/labels";
import { useAppContext } from "@/hooks/useAppContext";

import AffiliatesCarouselSkeleton from "../skeleton/AffiliatesCarouselSkeleton";
import AffiliatesItem from "./components/AffiliatesItem";

const AffiliatesCarousel = () => {
  const { affiliates } = useAppContext();

  return (
    <>
      <div className="px-4 pb-4 text-white">
        <div className="container px-0 pt-2 xl:max-w-screen-2xl">
          {affiliates?.length > 0 ? (
            <div className="no-scrollbar overflow-x-scroll">
              <ul className="flex justify-between gap-x-2 rounded-lg">
                {affiliates?.map((sportsbook) => (
                  <li key={sportsbook?.id}>
                    <AffiliatesItem sportsbook={sportsbook} />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <AffiliatesCarouselSkeleton />
          )}
          <div className="pb-0 pt-2 text-right">
            <Link
              href="/usa-sportsbooks/"
              className="text-blue underline"
              rel="review"
            >
              {labels.affiliateAllReview}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliatesCarousel;
