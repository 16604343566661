import Link from "next/link";

import Icon from "@/components/icon/Icon";
import labels from "@/config/labels";
import { HOMEPAGE_CATEGORIES_MENU } from "@/config/menus";
import { useAppContext } from "@/hooks/useAppContext";

const NewsCard = () => {
  const { categoryUris } = useAppContext();
  return (
    <div className="mb-4 mt-6 rounded-lg border border-card-border bg-white p-6 text-dark lg:shadow-lg">
      <h3 className="mb-2 text-[1.375rem] font-bold">
        {labels?.homepageCategoriesCard}
      </h3>
      {categoryUris && (
        <div className="my-4 flex flex-wrap">
          {Object.entries(HOMEPAGE_CATEGORIES_MENU)?.map(([key, item]) => (
            <Link
              aria-label={item}
              className="mb-2 mr-2 flex flex-row items-center rounded-full border border-category-grey px-6 py-[.725rem] font-heading text-[.8125rem] font-semibold text-category-grey hover:bg-category-grey hover:text-white"
              href={categoryUris?.[key] ?? ""}
              key={key}
            >
              <Icon
                className="mr-2 h-[16px] w-[20px] text-red"
                height={"16px"}
                color="#dc3545"
                name={`${key}Category`}
                width={"20px"}
              />
              {item}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default NewsCard;
