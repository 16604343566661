import { updateImageAndSponsoredAttributes } from "@/utilities/content-parser";

const Content = ({ content, customClass }) => {
  const parsedHtmlContent = updateImageAndSponsoredAttributes(content);

  return (
    <div
      id="bettingnews-content"
      className={customClass}
      dangerouslySetInnerHTML={{ __html: parsedHtmlContent || content }}
    />
  );
};

export default Content;
