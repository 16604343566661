import ContentLoader from "react-content-loader";

const AffiliatesCarouselSkeleton = ({ ...props }) => {
  return (
    <div className="flex content-start gap-x-2 overflow-x-auto pr-1">
      {Array.from({ length: 8 }).map((_, index) => {
        return (
          <ContentLoader
            speed={2}
            width={270}
            height={220}
            key={index}
            viewBox="0 0 270 220"
            backgroundColor="#f3f3f3"
            className="flex-none rounded-lg"
            foregroundColor="#ecebeb"
            animate
            {...props}
          >
            <rect x="9" y="5" rx="0" ry="0" width="270" height="220" />
          </ContentLoader>
        );
      })}
    </div>
  );
};

export default AffiliatesCarouselSkeleton;
