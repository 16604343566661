import Image from "next/image";
import { useState } from "react";

import StreamOffline from "@/public/live-stream-offline-image.webp";
import VideoFile from "@/public/live-stream-video.mp4";

function LiveStreamBanner() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleClick = () => {
    setIsVideoPlaying(true);
  };

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  return (
    <>
      {!isVideoPlaying ? (
        <Image
          src={StreamOffline}
          alt="Twitch Stream Offline"
          width={600}
          height={500}
          layout="responsive"
          className="rounded-lg"
          onClick={handleClick}
        />
      ) : (
        <video
          width="100%"
          height={500}
          className="rounded-lg"
          controls
          onEnded={handleVideoEnd}
          autoPlay
          preload="none"
        >
          <source src={VideoFile} type="video/mp4" />
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <track kind="captions" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
}

export default LiveStreamBanner;
