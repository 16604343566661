import { useEffect, useRef, useState } from "react";

import labels from "@/config/labels";

import LiveStreamBanner from "./LiveStreamBanner";

function TwitchEmbedded() {
  const twitchRef = useRef(null);
  const [isOnline, setIsOnline] = useState(false);
  const scriptAppendedRef = useRef(false);

  useEffect(() => {
    if (scriptAppendedRef.current) return;

    const script = document.createElement("script");
    script.src = "https://player.twitch.tv/js/embed/v1.js";
    script.async = true;
    document.body.appendChild(script);
    scriptAppendedRef.current = true;

    script.onload = () => {
      const options = {
        channel: "bettingnews",
        parent: ["bettingnews.com", "stagingnext.bettingnews.com"],
        autoplay: true,
        muted: true,
        width: "100%",
        height: 380,
      };
      const player = new Twitch.Player(twitchRef.current, options);

      const initiate = () => {
        player.addEventListener(Twitch.Player.ONLINE, handleOnline);
        player.addEventListener(Twitch.Player.OFFLINE, handleOffline);
        player.removeEventListener(Twitch.Player.READY, initiate);
      };

      const handleOnline = () => {
        setIsOnline(true);
        player.setMuted(true);
        player.removeEventListener(Twitch.Player.ONLINE, handleOnline);
        player.addEventListener(Twitch.Player.OFFLINE, handleOffline);
      };

      const handleOffline = () => {
        setIsOnline(false);
        player.setMuted(true);
        player.removeEventListener(Twitch.Player.OFFLINE, handleOffline);
        player.addEventListener(Twitch.Player.ONLINE, handleOnline);
      };

      player.addEventListener(Twitch.Player.READY, initiate);
    };

    return () => {
      script.remove();
    };
  }, []);

  return (
    <div className="my-8">
      <h2 className="mb-2 font-heading text-lg font-bold leading-[1.15] text-nav-blue sm:text-[1.6rem]">
        {labels.homepageLiveStream}
      </h2>
      {!isOnline && <LiveStreamBanner />}
      <div ref={twitchRef} className={isOnline ? "" : "hidden"}></div>
    </div>
  );
}

export default TwitchEmbedded;
