import Link from "next/link";

import CustomImage from "@/components/CustomImage";
import BestBetsCardSkeleton from "@/components/skeleton/BestBetsCardSkeleton";
import labels from "@/config/labels";
import { useAppContext } from "@/hooks/useAppContext";
import useScreenWidth from "@/hooks/useScreenWidth";

const BestBetsCard = () => {
  const { bestBetsPages } = useAppContext();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 991;

  return (
    <>
      {bestBetsPages.length > 1 ? (
        <div className="block">
          <div className="mb-6 rounded-lg border border-card-border bg-blue text-white lg:shadow-lg">
            <h2 className="mb-2 pl-6 pt-4 text-[1.375rem] font-bold">
              {labels.bestBetsTitle}
            </h2>
            <div className="m-4 flex flex-col gap-4 rounded-lg">
              {bestBetsPages.length > 0 ? (
                bestBetsPages.map((item, index) => {
                  return (
                    <div
                      className="flex justify-between rounded-lg bg-white font-body text-dark"
                      key={index}
                    >
                      <div className="w-full">
                        <div className="h-full w-full">
                          <div className="hidden">
                            {item?.node?.featuredImage?.node?.sourceUrl}
                          </div>
                          <CustomImage
                            className="h-[100px] w-full rounded-l-lg"
                            src={item?.node?.featuredImage?.node?.sourceUrl}
                            alt={item?.node?.featuredImage?.node?.altText}
                            width={500}
                            height={200}
                            sizes="100vw"
                          />
                        </div>
                      </div>
                      <div className="m-2 w-full">
                        <Link
                          href={item?.node?.uri ?? ""}
                          className={`ellipsis-lg font-heading text-[0.925rem] font-semibold leading-5 text-card-title ${isMobile ? "ellipsis" : ""}`}
                        >
                          {item?.node?.title}
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <BestBetsCardSkeleton />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="hidden"></div>
      )}
    </>
  );
};

export default BestBetsCard;
