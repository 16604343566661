import Link from "next/link";

import { SOCIAL_GOOGLE, SOCIAL_LINKS } from "@/config/constants";
import labels from "@/config/labels";
import useScreenWidth from "@/hooks/useScreenWidth";

import Icon from "./icon/Icon";

const Tagline = () => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 480;

  return (
    <div className="items-left mb-6 flex flex-col">
      <h1 className="font-heading text-2xl font-bold leading-[1.75] text-nav-blue sm:text-[2rem]">
        {labels.homepageTitle}
      </h1>
      <h2 className="font-heading text-lg font-bold leading-[1.15] text-nav-blue sm:text-[1.6rem]">
        {labels.homepageSubtitle}
      </h2>
      <p className=" py-1">{labels.homepageSiteDescription}</p>
      <div
        className={`mt-4 flex flex-row items-center ${isMobile ? "gap-8" : "gap-4"}`}
      >
        <h2 className="text-sm text-darker-grey">
          {labels.footerSocialPrefix}
        </h2>
        <div
          className={`grid cursor-pointer items-center ${isMobile ? "grid-cols-4 gap-x-6 gap-y-4" : "grid-cols-8 gap-4"}`}
        >
          {Object.entries(SOCIAL_LINKS).map(([type, social]) => (
            <Link
              key={type}
              aria-label={type}
              target="_blank"
              href={social?.url ?? ""}
            >
              <Icon
                className={`h-[26px] w-8 ${type === "instagram" ? "insta-icon" : ""}`}
                color={social.color}
                height={32}
                name={type}
                width={26}
              />
            </Link>
          ))}
          {Object.entries(SOCIAL_GOOGLE).map(([type, social]) => (
            <Link
              key={type}
              aria-label={type}
              target="_blank"
              href={social?.url ?? ""}
            >
              <Icon
                className="h-[26px] w-8"
                color={social.color}
                height={32}
                name={type}
                width={26}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tagline;
