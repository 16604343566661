import ContentLoader from "react-content-loader";

const BestBetsCardSkeleton = ({ ...props }) => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <ContentLoader
            speed={2}
            width={362}
            height={100}
            key={index}
            viewBox="0 0 362 100"
            backgroundColor="#f3f3f3"
            className="w-full flex-none rounded-lg"
            foregroundColor="#ecebeb"
            animate
            {...props}
          >
            <rect x="9" y="5" rx="0" ry="0" width="362" height="100" />
          </ContentLoader>
        );
      })}
    </>
  );
};

export default BestBetsCardSkeleton;
