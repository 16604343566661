export function createSrcSet(url) {
  const widths = [256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840];
  const quality = 85;
  const srcSet = widths
    .map(
      (width) =>
        `/_next/image/?url=${encodeURIComponent(url)}&w=${width}&q=${quality} ${width}w`
    )
    .join(", ");

  return srcSet;
}

export function formatImageForSEO(images) {
  return images.map((image) => ({
    href: image,
    sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    srcSet: createSrcSet(image),
  }));
}
