export function updateImageAndSponsoredAttributes(htmlContent) {
  if (!htmlContent) return "";

  const internalAffiliateRegex = new RegExp(
    "https://www\\.bettingnews\\.com/sportsbooks/\\w+/",
    "g"
  );
  const externalAffiliateRegex = new RegExp(
    "https://affiliates\\.bettingnews\\.com/register/\\w+/",
    "g"
  );

  // Function to check if a link is an affiliate link
  const isAffiliateLink = (href) => {
    return (
      href &&
      (href.match(externalAffiliateRegex) || href.match(internalAffiliateRegex))
    );
  };

  // Function to process link (<a>) tags
  const processLinks = (tag) => {
    const hrefIndex = tag?.indexOf('href="');
    if (hrefIndex === -1) return tag; // No href attribute found

    const start = hrefIndex + 6; // Start of the URL in href
    const end = tag?.indexOf('"', start); // End of the URL
    const href = tag?.substring(start, end);

    if (isAffiliateLink(href)) {
      const relIndex = tag?.indexOf('rel="');
      const targetIndex = tag?.indexOf('target="');
      if (relIndex === -1) {
        tag = tag?.slice(0, end + 1) + ' rel="sponsored"' + tag?.slice(end + 1);
      }
      if (targetIndex === -1) {
        tag = tag?.slice(0, end + 1) + ' target="_blank"' + tag?.slice(end + 1);
      }
    }

    return tag;
  };

  // Main processing of HTML content
  return htmlContent?.replace(/<a [^>]*>/g, processLinks);
}
