import { useGamesContext } from "@/hooks/useGamesContext";

import BestBetsCard from "./components/BestBetsCard";
import GuidesCard from "./components/GuidesCard";
import NewsCard from "./components/NewsCard";
import Newsletter from "./components/Newsletter";

const Sidebar = () => {
  const { games } = useGamesContext();
  const noGames = !games || games.length === 0;
  return (
    <div
      className={`right-menu sticky rounded-lg ${noGames ? "top-[8rem] " : ""}`}
    >
      <div
        className={`no-scrollbar my-4 grid grid-cols-1 content-start gap-2 overflow-y-auto pr-1 ${noGames ? "nogames-sidebar" : "menu-sidebar"}`}
      >
        <div>
          <BestBetsCard />
          <GuidesCard />
          <NewsCard />
          <div className="text-white">
            <Newsletter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
